import {Box, Typography} from "@mui/material";
import BlackButton from "../BlackButton";
import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {useTheme} from "@mui/material/styles";
import useWidth from "../../../utils/useWidth";

type BookShowingLineProps = {
  text: string
  onClick: () => void
  key: number | string
  href: string
}

export default function BookShowingLine(props: BookShowingLineProps) {
  const {text, onClick, key, href} = props
  const width = useWidth()

  return (
    <Box
      key={key}
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
      }}
    >
      <Typography
        sx={width === 'lg' ? {
          fontFamily: 'Lato-Light',
          fontSize: '20px',
          color: '#2F2E41',
        } : {
          fontFamily: 'Roboto',
          fontSize: '16px',
          color: '#2F2E41',
        }}
      >
        {text}
      </Typography>

      <Box
        sx={{ marginLeft: 'auto' }}
        onClick={onClick}
      >
        <AnchorLink
          href={href}
          offset={45}
          style={{ textDecoration: 'none' }}
        >
          <BlackButton
            fontFamily="Lato"
            width="128px"
            height="41px"
            fontSize="17px"
            name="Boka"
          />
        </AnchorLink>
      </Box>
    </Box>
  );
}
