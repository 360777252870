import {swedishDay, swedishMonth} from "../components/organs/ObjectPage/HouseInfo/houseHelpers";

export function formatShowingDate (startTime: Date, endTime: Date) {
  return swedishDay(startTime.getDay()) +
    ' ' +
    startTime.getDate() +
    ' ' +
    swedishMonth(startTime.getMonth()).toLowerCase() +
    ' kl. ' +
    ('0' + startTime.getHours()).slice(-2) +
    '.' +
    ('0' + startTime.getMinutes()).slice(-2) +
    '-' +
    ('0' + endTime.getHours()).slice(-2) +
    '.' +
    ('0' + endTime.getMinutes()).slice(-2)
}
