import {ObjectBaseInfo} from "../types/object";

export function getVisibleShowings(objectData: ObjectBaseInfo) {
  return objectData.showings
    .sort((a, b) => (a.TimeStart > b.TimeStart ? 1 : -1))
    .filter((item) => {
      const timeEnd = new Date(item.TimeEnd);
      if (timeEnd > new Date()) return 1;
      return -1;
    })
}
