import React from 'react';
import ContactFormXS from './ContactFormXS';
import ContactFormLG from './ContactFormLG';
import useWidth from '../../../utils/useWidth';

const ResponsiveComponent = ({
  message,
  object
}) => {
  const width = useWidth();
  return (
    <>
      {width === 'lg' || width === 'xl' ? (
        <ContactFormLG
          message={message}
          object={object}
        />
      ) : (
        <ContactFormXS
          message={message}
          object={object}
        />
      )}
    </>
  );
};

const ContactForm = ({ object, message }) => {
  return (
      <ResponsiveComponent
        object={object}
        message={message}
      />
  );
};

export default ContactForm;
